import { startOfDay, subDays } from 'date-fns';

import ReportExportSettings from '../../models/ReportExportSettings.js';
import ReportTemplate from '../../models/ReportTemplate.js';
import SeparationByHourUtils from '../../utils/separation-by-hour-utils.js';

function ReportsGenerateExportController(
	$rootScope,
	$scope,
	$timeout,
	API_ERRORS,
	close,
	templateId,
	InfoService,
	ModalCloser,
	ReportExports,
	ReportTemplates,
	toaster,
) {
	'ngInject';

	const MAXIMUM_ANALYTICS_REPORT_RANGE_DAYS = 90;
	const MAXIMUM_LOSS_RATE_REPORT_RANGE_DAYS = 30; // It needs to be 30 and not 31 because we don't take hours into account

	const vm = this;

	vm.template = new ReportTemplate(ReportTemplates.getById(templateId).toObject());

	vm.maxDate = vm.template.period.getMaxDate();
	vm.minDate = null;
	if (vm.template.isAnalyticsReport()) {
		vm.minDate = startOfDay(subDays(new Date(), MAXIMUM_ANALYTICS_REPORT_RANGE_DAYS));
	}
	if (vm.template.isLossRateReport()) {
		vm.minDate = startOfDay(subDays(new Date(), MAXIMUM_LOSS_RATE_REPORT_RANGE_DAYS));
	}
	vm.template.period.clampDates(vm.maxDate, vm.minDate);
	vm.filenameAutofill = !vm.template.getFilename();
	if (vm.filenameAutofill) {
		vm.template.setFilename(vm.template.getFilename() || vm.template.name);
	}

	vm.filetypeOptions = ReportExportSettings.getFiletypeSelectOptions();
	vm.separateByOptions = vm.template.getSeparatedByOptions();
	vm.startHourOptions = vm.template.period.getValidStartHourOptions();
	vm.endHourOptions = vm.template.period.getValidEndHourOptions();

	vm.errorDateBeforeMessage = false;
	vm.error24HoursRangeMessage = false;

	vm.selectTimePreset = () => {
		vm.maxDate = vm.template.period.getMaxDate();
		vm.template.period.setStartAndEndAccordingToType();
		vm.template.period.clampDates(vm.maxDate);
		vm.template.refreshSeparateByOptions();
		vm.template.resetHourSelection();
		vm.validateDateTime();
	};

	vm.onSeparateByChange = () => {
		vm.template.changeSeparateBy();
		vm.validateDateTime();
	};

	vm.onDateChange = () => {
		vm.template.resetHourOptions();
		vm.validateDateTime();
	};

	vm.onDateTimeChange = () => {
		vm.template.period.setStartAndEndAccordingToType();
		vm.validateDateTime();
	};

	vm.validateDateTime = () => {
		const errors = vm.template.validateDateTime();
		vm.errorDateBeforeMessage = errors.dateBefore;
		vm.error24HoursRangeMessage = errors.exceedsHoursRange;
	};

	vm.hasInvalidDateOrTime = () => {
		if (vm.template.separateBy.isSeparateByHour()) {
			return vm.errorDateBeforeMessage || vm.error24HoursRangeMessage;
		}
		return vm.errorDateBeforeMessage;
	};

	vm.isStartDateAfterEndDate = () => {
		return vm.errorDateBeforeMessage;
	};

	vm.exceeds24HoursDuration = () => {
		return vm.error24HoursRangeMessage;
	};

	vm.getFormattedHourOption = (hour) => {
		return SeparationByHourUtils.getFormattedHourOption(hour);
	};

	vm.isValidExportName = () => vm.template.exportSettings.isValidFilename();

	vm.isFormValid = () => vm.isValidExportName() && vm.template.period.isValid();

	vm.submit = () => {
		const errorEl = document.querySelector('.error');
		if (errorEl) {
			$timeout(() => {
				errorEl.focus();
			}, 0);
			return;
		}

		if (!vm.isFormValid()) {
			return;
		}

		InfoService.startRequest();
		ReportExports.addTemplateToQueue(vm.template)
			// .then() InfoService.endRequest() is called in reports overview controller
			.then((reportId) => closeFn(reportId, 200))
			.catch((error) => {
				InfoService.endRequest();

				if (error.code === API_ERRORS.INVALID_REPORT_DATE) {
					if (vm.template.isAnalyticsReport()) {
						toaster.errorMessage('TEXT.REPORTS_TEMPLATE_DATE_RANGE_INFO_ANALYTICS');
					} else if (vm.template.isLossRateReport()) {
						toaster.errorMessage('TEXT.REPORTS_TEMPLATE_DATE_RANGE_INFO_LOSS_RATE');
					}
				} else {
					toaster.errorMessage('MESSAGE.GENERATE_EXPORT_ERROR');
				}
			});
	};

	const closeFn = (result, delay) => {
		ModalCloser.close(close, result, delay);
	};

	vm.cancel = () => {
		closeFn(undefined, 200);
	};

	const unregisterSessionTimeoutListener = $rootScope.$on('event:auth-loginRequired', vm.cancel);

	$scope.$on('$destroy', unregisterSessionTimeoutListener);
}

export default ReportsGenerateExportController;
