import { format, startOfDay, subDays } from 'date-fns';

import INVOICE_TYPE_TRANSLATION from '../../models/PartnershipInvoiceTypeTranslation.js';
import ReportColumns from '../../models/ReportColumns.js';
import SORT_DIRECTION from '../../models/ReportColumnSortDirection.js';
import ReportColumnUiGroup from '../../models/ReportColumnUiGroup.js';
import ReportPeriod from '../../models/ReportPeriod.js';
import SeparationByHourUtils from '../../utils/separation-by-hour-utils.js';
import { ascByNameField } from '../../utils/sort-utils.js';

function ReportsViewController(
	$scope,
	$q,
	$rootScope,
	$filter,
	$state,
	$timeout,
	$window,
	resolvedTemplate,
	toaster,
	Account,
	Channels,
	PagerService,
	Partnerships,
	ReportTemplates,
	StringTools,
	Reports,
	ReportExports,
	ReportOrders,
	RevenueReport,
	ModalFactory,
	$translate,
	API_ERRORS,
	AUTH_EVENTS,
	AdslotTranslations,
) {
	'ngInject';

	//
	// run through description
	// these steps needs to be separated to be able to execute the needed step only (due to any user interaction)
	// each step depends on the one above
	// if a change was made in one step all steps below needs to be executed again
	//
	//
	// 1. load report (report.load())
	//    a report should be loaded if date, advertiser, extid or adid changes only
	//
	// 2. group report rows by visible columns (groupReport() sets local 'groupedReport')
	//    a report should be grouped if a text column is added or removed only
	//
	// 3. filter report according input text (filterReport() sets local 'filteredReport')
	//
	// 4 create total summary row (createSummaryRow() sets 'totalSummary')
	//    this step needs to be executed if a new report is loaded only due next steps does not affect the result
	//
	// 5. order rows by value if a column is selected (orderReport() sets local 'orderedReport')
	// 5.1 add sort summary row to ordered column
	//
	// 6. split report into page (getPagedReport() sets local 'pagedReport')
	//
	// 7. set paged report with (total and sort)summary rows into scope
	//

	let unbindDestroyListener, currentFilterTimeout, currentPagerTimeout;

	// Always include those dimensions in order to avoid some reloads.
	const DIMENSIONS_TO_FETCH_ALWAYS = ['site', 'group', 'format', 'invoice'];

	const WAIT_DURATIONS = [1, 1, 2, 3, 5, 8, 13, 21];
	let waitIndex = 0;

	const MAXIMUM_LOSS_RATE_REPORT_RANGE_DAYS = 30; // It needs to be 30 and not 31 because we don't take hours into account

	let reportOrderId = null;
	let waitingForReport = false;
	let reportOrderTimer = null;

	let lastDataConfig;
	let lastFilterSettings;

	// constants
	$scope.PAGER_ID = 'reports-table-' + resolvedTemplate.id;
	$scope.snakeCase = StringTools.snakeCase;
	// data
	$scope.report = new RevenueReport([], resolvedTemplate);
	$scope.reportRows = [];
	$scope.columns = resolvedTemplate.getAllColumns();
	$scope.visibleColumns = []; // set by view html
	// flags
	$scope.reportSettingsChanged = false;
	$scope.reportLoading = false;
	$scope.saveInProgress = false;
	$scope.reportError = '';

	$scope.datePresetOptions = resolvedTemplate.separateBy.isSeparateByHour()
		? ReportPeriod.getTimePresetSelectSeparatedByHourOptions()
		: resolvedTemplate.isLossRateReport()
			? ReportPeriod.getTimePresetSelectOptionsLossRateReport()
			: ReportPeriod.getTimePresetSelectOptions();

	const adTypes = AdslotTranslations.getAdTypes();
	const platformTypes = AdslotTranslations.getPlatformTypes();

	const getAdslotTypesIdNameSelectOptions = AdslotTranslations.getAdTypesAsArraySortAscNameField().map((adType) => ({
		name: adType.name,
		value: adType.id,
	}));

	const getPlatformTypesIdNameSelectOptions = AdslotTranslations.getPlatformTypesAsArraySortAscNameField().map((platformType) => ({
		name: platformType.name,
		value: platformType.id,
	}));

	$scope.template = resolvedTemplate;
	if ($scope.template.getColumn(ReportColumns.ID.CHANNELS)) {
		$scope.template.getColumn(ReportColumns.ID.CHANNELS).filter.setOptions(Channels.getIdNameSelectOptions().sort(ascByNameField));
	}
	if ($scope.template.getColumn(ReportColumns.ID.INVOICE)) {
		$scope.template.getColumn(ReportColumns.ID.INVOICE).filter.setOptions(Partnerships.getInvoiceTypesIdNameSelectOptions());
	}
	if ($scope.template.getColumn(ReportColumns.ID.ADTYPE)) {
		$scope.template.getColumn(ReportColumns.ID.ADTYPE).filter.setOptions(getAdslotTypesIdNameSelectOptions);
	}
	if ($scope.template.getColumn(ReportColumns.ID.PLATFORM_TYPE)) {
		$scope.template.getColumn(ReportColumns.ID.PLATFORM_TYPE).filter.setOptions(getPlatformTypesIdNameSelectOptions);
	}
	if ($scope.template.getColumn(ReportColumns.ID.PARTNERSHIP_TYPE)) {
		$scope.template.getColumn(ReportColumns.ID.PARTNERSHIP_TYPE).filter.setOptions(Partnerships.getPartnershipTypesIdNameSelectOptions());
	}

	if (!Account.isDirectConnect()) {
		$scope.template.removeParentRevenueColumn();
		$scope.template.removeParentSupplyPartnerColumn();
		$scope.template.removeParentDealIdColumn();
	}

	if (Account.isDirectConnect() && !Account.acEnabledDealTargetingDcDeal()) {
		$scope.template.removeParentSupplyPartnerColumn();
		$scope.template.removeParentDealIdColumn();
	}

	// Removed YP Requests as it will always be 0 for DC and non-yp-enabled Accounts, see YL-5545
	if (Account.isDirectConnect() && !Account.shouldIncludeYPRequests()) {
		$scope.template.removeYPRequestsColumn();
	}

	$scope.templateName = angular.copy(resolvedTemplate.name);

	if ($scope.template.separateBy.isSeparateByHour()) {
		$scope.template.setHourSelectionOptions();
	}

	$scope.errorDateBeforeMessage = false;
	$scope.error24HoursRangeMessage = false;

	let exportStartDate = new Date(resolvedTemplate.period.start.valueOf());
	let exportEndDate = new Date(resolvedTemplate.period.end.valueOf());

	const saveDataConfig = (template) => {
		lastFilterSettings = template.getFilters();
		lastDataConfig = {
			adslots: template.isAnyAdslotColumnVisible(),
			extids: template.getColumn(ReportColumns.ID.EXTERNAL_ID)?.visible ?? false,
			advertisers: template.getColumn(ReportColumns.ID.ADVERTISER) ? template.getColumn(ReportColumns.ID.ADVERTISER).visible : false,
			video: template.isAnyVideoColumnVisible(),
			filtersChanged: false,
		};
	};

	saveDataConfig($scope.template);

	$scope.selectTimePreset = () => {
		$scope.initializeDataRange();

		if ($scope.template.separateBy.isSeparateByHour() && $scope.template.period.isCustom()) {
			$scope.template.resetHourSelection();
		}

		$scope.validateDateTime();
	};

	$scope.initializeDataRange = () => {
		$scope.minDate = null;
		if ($scope.template.isLossRateReport()) {
			$scope.minDate = startOfDay(subDays(new Date(), MAXIMUM_LOSS_RATE_REPORT_RANGE_DAYS));
		}
		$scope.maxDate = $scope.template.period.getMaxDate();
		$scope.template.period.setStartAndEndAccordingToType();
		$scope.template.period.clampDates($scope.maxDate, $scope.minDate);
	};

	// Initialize data range
	$scope.initializeDataRange();

	$scope.reloadReport = () => {
		if ($scope.reportLoading) return;

		$scope.reportError = '';
		$scope.reportLoading = true;
		ReportOrders.createReportOrderFromTemplate($scope.template, false, DIMENSIONS_TO_FETCH_ALWAYS)
			.then((result) => {
				reportOrderId = result.id;
				waitingForReport = true;
				waitIndex = 0;
			})
			.then(scheduleStatusCheck)
			.catch(() => {
				clearPendingReport();
				$scope.reportError = 'MESSAGE.REQUEST_REPORT_ERROR';
			});
	};

	$scope.onDateChange = () => {
		$scope.template.resetHourOptions();
		$scope.validateDateTime();
	};

	$scope.onDateTimeChange = () => {
		$scope.validateDateTime();
	};

	$scope.validateDateTime = () => {
		const errors = $scope.template.validateDateTime();
		$scope.errorDateBeforeMessage = errors.dateBefore;
		$scope.error24HoursRangeMessage = errors.exceedsHoursRange;
	};

	$scope.isStartDateAfterEndDate = () => {
		return $scope.errorDateBeforeMessage;
	};

	$scope.exceeds24HoursDuration = () => {
		return $scope.error24HoursRangeMessage;
	};

	$scope.hasInvalidDateOrTime = () => {
		if ($scope.template.separateBy.isSeparateByHour()) {
			return $scope.errorDateBeforeMessage || $scope.error24HoursRangeMessage;
		}
		return $scope.errorDateBeforeMessage;
	};

	$scope.getFormattedHourOption = (hour) => {
		return SeparationByHourUtils.getFormattedHourOption(hour);
	};

	const scheduleStatusCheck = () => {
		if (reportOrderTimer !== null) {
			return;
		}
		if (waitingForReport) {
			reportOrderTimer = getProgressiveWaitTimer();
			reportOrderTimer
				.then(() => {
					reportOrderTimer = null;
				})
				.then(() => ReportOrders.getStatusById(reportOrderId))
				.then((statusResponse) => {
					if (statusResponse.status === 'PROCESSED') {
						return getFinishedReport(reportOrderId);
					} else if (statusResponse.status !== 'QUEUED') {
						return $q.reject('Report failed');
					}
					return $q.when(true);
				})
				.then(scheduleStatusCheck)
				.catch(() => {
					clearPendingReport();
					$scope.reportError = 'MESSAGE.REQUEST_REPORT_ERROR';
				});
		}
	};

	const getProgressiveWaitTimer = () => {
		const timer = $timeout(WAIT_DURATIONS[waitIndex] * 1000);
		if (waitIndex < WAIT_DURATIONS.length - 1) {
			waitIndex++;
		}
		return timer;
	};

	const clearPendingReport = () => {
		reportOrderId = null;
		waitingForReport = false;
		waitIndex = 0;
		$scope.reportLoading = false;
	};

	const getFinishedReport = (reportUUID) =>
		ReportOrders.getJsonReport(reportUUID)
			.then((reportData) => {
				$scope.report = new RevenueReport(reportData, $scope.template);
				saveDataConfig($scope.template);

				exportStartDate = new Date($scope.template.period.start.valueOf());
				exportEndDate = new Date($scope.template.period.end.valueOf());
			})
			.catch((errorObject) => {
				if (errorObject.code === API_ERRORS.REPORT_TIMEOUT) {
					$scope.reportError = 'MESSAGE.REQUEST_TIMEOUT';
				} else {
					$scope.reportError = 'MESSAGE.REQUEST_REPORT_ERROR';
				}
			})
			.finally(() => {
				clearPendingReport();

				$scope.report.update();
				$scope.columns = $scope.template.getAllColumns();
				$scope.columns.forEach((column) => showColumnIfNecessary(column));
				setDataToScope();
				$scope.reportSettingsChanged = false;
			});

	const showColumnIfNecessary = (column) => {
		if (column.isVisible()) return;

		// last dimension/key figure should remain visible
		if (
			(column.isDimension() && $scope.template.getVisibleDimensionColumns().length < 1) ||
			(column.isKeyFigure() && $scope.template.getVisibleKeyFigureColumns().length < 1)
		) {
			column.show();
			return;
		}

		// we have to enforce several columns otherwise the configured filters would have no effect.
		if (
			(column.id === ReportColumns.ID.ADSLOT_ID && column.filter.model.length > 0) ||
			(column.id === ReportColumns.ID.ADVERTISER_ID && column.filter.model.length > 0) ||
			(column.id === ReportColumns.ID.DEMAND_PARTNER_ID && column.filter.model.length > 0) ||
			(column.id === ReportColumns.ID.PARTNERSHIP_ID && column.filter.model.length > 0)
		) {
			column.show();
		}
	};

	$scope.editReport = () => {
		ModalFactory.editReport({
			template: angular.copy($scope.template),
		});
	};

	$scope.canExport = () => {
		return $scope.template.getVisibleExportColumns().length && $scope.reportRows.length;
	};

	$scope.exportReport = async (type) => {
		const template = $scope.template;

		let reportData = [];
		switch (type) {
			case 'xlsx':
				if (template.activeSortColumnId !== '' && template.getColumn(template.activeSortColumnId).isDimension()) {
					reportData = RevenueReport.addSummaryRows($scope.report.ordered, template.activeSortColumnId).slice();
				} else {
					reportData = $scope.report.ordered;
				}
				break;
			case 'csv':
				reportData = $scope.report.ordered;
				break;
			default:
				return;
		}

		const visibleColumnIds = template.getVisibleExportColumns();
		const requestParams = {};

		requestParams.report = [...reportData].concat(type === 'xlsx' ? [$scope.totalSummaryRow] : []).map((reportRow) => {
			const row = reportRow.getByColumnIds(visibleColumnIds);
			// do not forget to translate channel ids to readable channel names if necessary
			if (row[ReportColumns.ID.CHANNELS]) {
				row[ReportColumns.ID.CHANNELS] = row[ReportColumns.ID.CHANNELS]
					.map((channelId) => (Channels.getById(channelId) && Channels.getById(channelId).name) || '')
					.join(', ');
			}
			if (row[ReportColumns.ID.INVOICE]) {
				row[ReportColumns.ID.INVOICE] = $filter('translate')(INVOICE_TYPE_TRANSLATION[row[ReportColumns.ID.INVOICE]]);
			}
			if (row[ReportColumns.ID.ADTYPE]) {
				row[ReportColumns.ID.ADTYPE] = adTypes[row[ReportColumns.ID.ADTYPE]];
			}
			if (row[ReportColumns.ID.PLATFORM_TYPE]) {
				row[ReportColumns.ID.PLATFORM_TYPE] = platformTypes[row[ReportColumns.ID.PLATFORM_TYPE]];
			}
			if (angular.isDefined(row[ReportColumns.ID.PARTNERSHIP_TYPE])) {
				row[ReportColumns.ID.PARTNERSHIP_TYPE] = angular.isDefined(Partnerships.TYPES[row[ReportColumns.ID.PARTNERSHIP_TYPE]])
					? $filter('translate')(Partnerships.TYPES[row[ReportColumns.ID.PARTNERSHIP_TYPE]].text)
					: '';
			}

			return row;
		});

		requestParams.preset = {
			columns: visibleColumnIds,
			startDate: format(exportStartDate, ReportPeriod.REQUEST_DATE_FORMAT),
			endDate: format(exportEndDate, ReportPeriod.REQUEST_DATE_FORMAT),
			name: template.name,
		};

		requestParams.translation = visibleColumnIds.reduce((translation, columnId) => {
			translation[columnId] = $filter('translate')(template.getColumn(columnId).name);
			return translation;
		}, {});

		try {
			if (type === 'xlsx') {
				await Reports.exportExcel(requestParams.report, requestParams.preset, requestParams.translation);
			} else {
				Reports.exportCsv(requestParams.report, requestParams.preset, requestParams.translation);
			}
		} catch (error) {
			if (!$scope.$root.$$phase) {
				$scope.$apply(() => {
					$scope.reportError = 'MESSAGE.REQUEST_GENERAL_REPORT_ERROR';
				});
			} else {
				$scope.reportError = 'MESSAGE.REQUEST_GENERAL_REPORT_ERROR';
			}
		}
	};

	$scope.deleteTemplate = () => {
		$translate('MESSAGE.DELETE_PRESET_CONFIRMATION').then((confirmationText) => {
			// eslint-disable-next-line no-alert, no-restricted-globals
			if (!$scope.reportLoading && !$scope.saveInProgress && confirm(confirmationText + '\n' + $scope.templateName)) {
				ReportTemplates.delete($scope.template)
					.then(() => $state.go('reports.overview'))
					.then(() => toaster.successMessage($translate.instant('MESSAGE.REPORT_TEMPLATE_DELETE_SUCCESS')))
					.catch(() => toaster.errorMessage($translate.instant('MESSAGE.REPORT_TEMPLATE_DELETE_ERROR')));
			}
		});
	};

	/**
	 * @param {ReportColumn} column
	 * @returns {void}
	 */
	$scope.sort = (column) => {
		$scope.template.sort(column);
		$scope.report.updateOrder();
		setDataToScope();
	};

	$scope.getColumnSortClass = (column) => {
		if ($scope.template.activeSortColumnId === column.id) {
			if (column.sortDirection === SORT_DIRECTION.ASC) {
				return 'icon-up-dir';
			}

			return 'icon-down-dir';
		}

		return 'icon-arrow-combo';
	};

	$scope.onFiltersChange = () => {
		if (currentFilterTimeout) {
			$timeout.cancel(currentFilterTimeout);
		}

		currentFilterTimeout = $timeout(() => {
			$scope.report.updateFilter();
			setDataToScope();
		}, 250);
	};

	const setDataToScope = () => {
		$scope.reportRows = getPagedReport();
		$scope.totalSummaryRow = RevenueReport.summarizeRows($scope.report.ordered, true);
	};

	/**
	 * @returns {Array<ReportRow>}
	 */
	const getPagedReport = () => {
		const pagedReport = $filter('pager')($scope.report.ordered, $scope.PAGER_ID);

		if ($scope.template.activeSortColumnId !== '' && $scope.template.getColumn($scope.template.activeSortColumnId).isDimension()) {
			return RevenueReport.addSummaryRows(pagedReport, $scope.template.activeSortColumnId);
		}

		return pagedReport;
	};

	$scope.getTotalColumnValue = (column) =>
		column.isKeyFigure() && column.id !== ReportColumns.ID.LOSS_RATE ? $scope.getColumnValue($scope.totalSummaryRow, column.id) : '';

	/**
	 * @param {ReportRow} reportRow
	 * @param {string} columnId
	 * @returns {string|number}
	 */
	$scope.getColumnValue = (reportRow, columnId) => {
		// complex value handling
		if (columnId === ReportColumns.ID.CHANNELS) {
			return reportRow[ReportColumns.ID.CHANNELS].map((channelId) => (Channels.getById(channelId) && Channels.getById(channelId).name) || '').join(', ');
		} else if (columnId === ReportColumns.ID.INVOICE) {
			return $filter('translate')(INVOICE_TYPE_TRANSLATION[reportRow[columnId]]);
		} else if (columnId === ReportColumns.ID.ADTYPE && Boolean(reportRow[columnId])) {
			return adTypes[reportRow[columnId]];
		} else if (columnId === ReportColumns.ID.PLATFORM_TYPE && Boolean(reportRow[columnId])) {
			return platformTypes[reportRow[columnId]];
		} else if (columnId === ReportColumns.ID.PARTNERSHIP_TYPE && angular.isDefined(Partnerships.TYPES[reportRow[columnId]])) {
			return $filter('translate')(Partnerships.TYPES[reportRow[columnId]].text);
		}

		// primitive value handling
		// @todo integrate in column (definition) ?!
		switch (columnId) {
			case ReportColumns.ID.IMPRESSIONS:
			case ReportColumns.ID.DELIVERIES:
			case ReportColumns.ID.FALLBACKS:
				return $filter('number')(reportRow[columnId]);
			case ReportColumns.ID.VIDEO_STARTS:
			case ReportColumns.ID.VIDEO_FIRST_QUARTILE:
			case ReportColumns.ID.VIDEO_MIDPOINT:
			case ReportColumns.ID.VIDEO_THIRD_QUARTILE:
			case ReportColumns.ID.VIDEO_COMPLETE:
			case ReportColumns.ID.VIDEO_CLICK:
				return $filter('numberIfNumber')(reportRow[columnId]);
			case ReportColumns.ID.REVENUE:
			case ReportColumns.ID.NET_REVENUE:
			case ReportColumns.ID.PARENT_REVENUE:
			case ReportColumns.ID.ECPM:
			case ReportColumns.ID.NET_ECPM:
				return $filter('currency')(reportRow[columnId]);
			default:
				return reportRow[columnId];
		}
	};

	// ----- scrollbar ------
	const toggleAdditionalScrollbar = () => {
		let scrollContainer = angular.element('.table-top-scroll-container'),
			tableContainer = angular.element('.table-container'),
			viewportHeight = angular.element($window).height(),
			viewportTopScroll = angular.element($window).scrollTop(),
			tableTopPosition = tableContainer.position().top,
			tableHeight = tableContainer.height(),
			originalScrollbarPosition = tableTopPosition + tableHeight - viewportTopScroll;

		scrollContainer.width(tableContainer.width());
		scrollContainer.find('div').width(tableContainer.find('table')[0].scrollWidth);

		if (tableTopPosition - viewportTopScroll < viewportHeight && originalScrollbarPosition > viewportHeight) {
			scrollContainer.scrollLeft(tableContainer.scrollLeft());
			scrollContainer.show();
		} else {
			scrollContainer.hide();
		}
	};

	angular.element('.table-top-scroll-container').on('scroll', function scroll() {
		angular.element('.table-container').scrollLeft(angular.element('.table-top-scroll-container').scrollLeft());
	});
	angular.element('.table-container').on('scroll', function scroll() {
		angular.element('.table-top-scroll-container').scrollLeft(angular.element('.table-container').scrollLeft());
	});

	toggleAdditionalScrollbar();
	angular.element($window).on('resize', toggleAdditionalScrollbar);
	angular.element($window).on('scroll', toggleAdditionalScrollbar);

	// ----- watch -----
	$scope.$watch(
		'template.period',
		(period, old) => {
			if (angular.isDefined(old) && period !== old) {
				$scope.invalidDateSelection = $scope.errorDateBeforeMessage || $scope.error24HoursRangeMessage;

				const missingTime = $scope.template.period.startHour == null || $scope.template.period.endHour == null;

				if (!$scope.invalidDateSelection && !missingTime) {
					$scope.reportSettingsChanged = true;
				} else {
					$scope.reportSettingsChanged = false;
				}
			}
		},
		true,
	);

	$scope.$watch(
		() => PagerService.get($scope.PAGER_ID).perPage,
		() => {
			if (currentPagerTimeout) {
				$timeout.cancel(currentPagerTimeout);
			}
			currentPagerTimeout = $timeout(() => setDataToScope(), 250);
		},
	);

	$scope.$watch(
		() => PagerService.get($scope.PAGER_ID).page,
		() => {
			if (currentPagerTimeout) {
				$timeout.cancel(currentPagerTimeout);
			}
			currentPagerTimeout = $timeout(() => setDataToScope(), 250);
		},
	);

	// ----- event listener: reload / save -----
	const removeReportReloadListener = $rootScope.$on(Reports.EVENT.RELOADED, (event, template) => {
		if (angular.isDefined(template)) {
			$scope.template = template;
			evaluateReportSettings();
			reloadOrUpdateReportView();
		}
	});

	const removeReportTemplateSaveListener = $rootScope.$on(Reports.EVENT.SAVED, (event, template) => {
		if (angular.isDefined(template)) {
			$scope.template = template;
			evaluateReportSettings();
			updateTemplate();
		}
	});

	const evaluateReportSettings = () => {
		let dataConfig = {};
		$scope.columns = $scope.template.getAllColumns();
		$scope.columns.forEach((column) => updateDataConfigForColumn(column, dataConfig));
		dataConfig.filtersChanged = !angular.equals($scope.template.getFilters(), lastFilterSettings);

		if (isReportFetchNeeded(lastDataConfig, dataConfig)) {
			$scope.reportSettingsChanged = true;
		}

		lastDataConfig = { ...dataConfig };
	};

	const updateDataConfigForColumn = (column, dataConfig) => {
		switch (column.id) {
			case ReportColumns.ID.EXTERNAL_ID:
				dataConfig.extids = column.visible;
				break;
			case ReportColumns.ID.ADVERTISER:
				dataConfig.advertisers = column.visible;
				break;
			default:
		}

		switch (column.group) {
			case ReportColumnUiGroup.ADSLOT:
				dataConfig.adslots = $scope.template.isAnyAdslotColumnVisible();
				break;
			case ReportColumnUiGroup.VIDEO:
				dataConfig.video = $scope.template.isAnyVideoColumnVisible();
				break;
			default:
		}
	};

	const isReportFetchNeeded = (lastConfig, currentConfig) => {
		return (
			lastConfig.advertisers !== currentConfig.advertisers ||
			lastConfig.extids !== currentConfig.extids ||
			lastConfig.adslots !== currentConfig.adslots ||
			lastConfig.video !== currentConfig.video ||
			lastConfig.filtersChanged !== currentConfig.filtersChanged
		);
	};

	const updateTemplate = () => {
		if (!isFormValid()) {
			return;
		}
		$scope.saveInProgress = true;
		ReportTemplates.update($scope.template)
			.then(() => {
				toaster.successMessage('MESSAGE.REPORT_SAVE_SUCCESS');
				$scope.templateName = angular.copy($scope.template.name);
			})
			.catch(() => toaster.errorMessage('MESSAGE.REPORTS_PRESET_SAVE_ERROR'))
			.finally(() => {
				$scope.saveInProgress = false;
				reloadOrUpdateReportView();
			});
	};

	const reloadOrUpdateReportView = () => {
		if ($scope.reportSettingsChanged) {
			$scope.reloadReport();
		} else {
			$scope.reportLoading = true;
			$scope.report.setTemplate($scope.template);
			$scope.report.update();
			setDataToScope();
			$scope.reportLoading = false;
		}
	};

	const isFormValid = () => $scope.template.name && !reportNameExists() && $scope.template.period.isValid();

	const reportNameExists = () =>
		ReportTemplates.getList().filter(
			(template) => template.name.toLowerCase() === ($scope.template.name || '').toLowerCase() && template.id !== $scope.template.id,
		).length > 0;

	unbindDestroyListener = $scope.$on('$destroy', function $destroyListener() {
		angular.element('.table-top-scroll-container').off('scroll');
		angular.element('.table-container').off('scroll');

		angular.element($window).off('resize', toggleAdditionalScrollbar);
		angular.element($window).off('scroll', toggleAdditionalScrollbar);
		removeReportReloadListener();
		removeReportTemplateSaveListener();

		unbindDestroyListener();
	});

	// trigger initial report loading - probably done better (via dedicated loading state, ... ) at some point
	$scope.reloadReport();

	const killReportOrderTimer = () => {
		if (reportOrderTimer !== null) {
			$timeout.cancel(reportOrderTimer);
			reportOrderTimer = null;
		}
	};

	// eslint-disable-next-line angular/on-watch
	$rootScope.$on(AUTH_EVENTS.userImpersonated, killReportOrderTimer);
	// eslint-disable-next-line angular/on-watch
	$rootScope.$on(AUTH_EVENTS.logoutSuccess, killReportOrderTimer);
}

export default ReportsViewController;
