import { startOfDay, subDays } from 'date-fns';

import ReportColumns from '../../models/ReportColumns.js';
import ReportSeparateBy from '../../models/ReportSeparateBy.js';
import ReportTemplate from '../../models/ReportTemplate.js';
import SeparationByHourUtils from '../../utils/separation-by-hour-utils.js';
import ReportColumnUiGroup from './../../models/ReportColumnUiGroup.js';
import ReportExportSettings from './../../models/ReportExportSettings.js';
import ReportPeriod from './../../models/ReportPeriod.js';

function ReportsNewEditController(
	$rootScope,
	$state,
	$translate,
	$timeout,
	Account,
	API_ERRORS,
	templateConfig,
	InfoService,
	ReportTemplates,
	ReportExports,
	Reports,
	ModalFactory,
	ModalCloser,
	close,
	toaster,
) {
	'ngInject';

	const MAXIMUM_ANALYTICS_REPORT_RANGE_DAYS = 90;
	const MAXIMUM_LOSS_RATE_REPORT_RANGE_DAYS = 30; // It needs to be 30 and not 31 because we don't take hours into account
	const vm = this;

	if (templateConfig.templateId) {
		if (!templateConfig.duplicate) {
			// edit template
			vm.template = new ReportTemplate(ReportTemplates.getById(templateConfig.templateId).toObject());
		} else {
			// duplicate template
			vm.template = new ReportTemplate({
				...ReportTemplates.getById(templateConfig.templateId).toObject(),
				notificationSettings: undefined,
			});
			const name = `${$translate.instant('CAPTION.COPY_OF')} ${vm.template.name}`;
			vm.template.setId(0).setName(name).setFilename('');
		}
	} else {
		// create new template
		vm.template = new ReportTemplate();
		vm.template.period.setType(ReportPeriod.TYPE.CURRENT_WEEK).setStartAndEndAccordingToType();
		vm.template.refreshSeparateByOptions();
	}

	// template id is set? -> edit mode
	vm.isEditMode = !!vm.template.id;
	vm.initialName = vm.template.name;
	vm.maxDate = vm.template.period.getMaxDate();
	setMinDate();

	vm.template.period.clampDates(vm.maxDate, vm.minDate);
	vm.filenameAutofill = !vm.template.getFilename();
	vm.reportValidity = {
		dimensionsValid: true,
		keyFiguresValid: true,
	};

	// pre-fill the filename field in case of old templates with no configured filename yet
	if (vm.isEditMode && vm.filenameAutofill) {
		vm.template.setFilename(vm.template.getFilename() || vm.template.name);
	}

	if (!Account.isDirectConnect()) {
		vm.template.removeParentRevenueColumn();
		vm.template.removeParentSupplyPartnerColumn();
		vm.template.removeParentDealIdColumn();
	}

	if (Account.isDirectConnect() && !Account.acEnabledDealTargetingDcDeal()) {
		vm.template.removeParentSupplyPartnerColumn();
		vm.template.removeParentDealIdColumn();
	}

	// Removed YP Requests as it will always be 0 for DC and non-yp-enabled Accounts, see YL-5545
	if (Account.isDirectConnect() && !Account.shouldIncludeYPRequests()) {
		vm.template.removeYPRequestsColumn();
	}

	vm.UI_GROUP = ReportColumnUiGroup;
	vm.TEMPLATE_TYPE = ReportTemplate.TYPE;

	vm.filetypeOptions = ReportExportSettings.getFiletypeSelectOptions();

	vm.SAVE_ACTION_TYPE = {
		OVERVIEW: 'overview',
		VIEW_REPORT: 'view',
		GENERATE_EXPORT: 'export',
	};
	vm.saveActionType = vm.SAVE_ACTION_TYPE.OVERVIEW;

	vm.COLUMN_HINTS = {
		[ReportColumns.ID.YIELDPROBE_REQUESTS]: 'TITLE.REPORTS.COLUMN_RESTRICTION.YIELDPROBE_REQUESTS',
	};

	vm.errorDateBeforeMessage = false;
	vm.error24HoursRangeMessage = false;

	vm.dateOptionsDisabled = (option) => {
		if (vm.template.hasScheduleConfigured()) {
			return !ReportPeriod.SCHEDULED_SUPPORT_PERIOD_TYPES.has(option.value);
		}
		return false;
	};

	vm.changeTemplateType = () => {
		const type = vm.template.type;
		vm.template = new ReportTemplate({ type });
		vm.template.period.setType(ReportPeriod.TYPE.CURRENT_WEEK);
		vm.template.separateBy = new ReportSeparateBy(ReportSeparateBy.SEPARATE_BY.TOTAL);

		setMinDate();
		vm.maxDate = vm.template.period.getMaxDate();
		vm.template.period.setStartAndEndAccordingToType();
		vm.template.period.clampDates(vm.maxDate, vm.minDate);

		vm.template.refreshSeparateByOptions();
		vm.template.refreshDatePresetOptions();

		// we do not support viewing reports online for analytics
		if (vm.template.isAnalyticsReport() && vm.saveActionType === vm.SAVE_ACTION_TYPE.VIEW_REPORT) {
			vm.saveActionType = vm.SAVE_ACTION_TYPE.OVERVIEW;
		}

		if (!Account.isDirectConnect()) {
			vm.template.removeParentRevenueColumn();
			vm.template.removeParentSupplyPartnerColumn();
			vm.template.removeParentDealIdColumn();
		}

		if (Account.isDirectConnect() && !Account.acEnabledDealTargetingDcDeal()) {
			vm.template.removeParentSupplyPartnerColumn();
			vm.template.removeParentDealIdColumn();
		}

		// Removed YP Requests as it will always be 0 for DC and non-yp-enabled Accounts, see YL-5545
		if (Account.isDirectConnect() && !Account.shouldIncludeYPRequests()) {
			vm.template.removeYPRequestsColumn();
		}

		vm.errorDateBeforeMessage = false;
		vm.error24HoursRangeMessage = false;
	};

	vm.selectTimePreset = () => {
		vm.maxDate = vm.template.period.getMaxDate();
		vm.template.period.setStartAndEndAccordingToType();
		vm.template.period.clampDates(vm.maxDate);
		vm.template.refreshSeparateByOptions();
		vm.template.resetHourSelection();
		vm.validateDateTime();
	};

	vm.onSeparateByChange = () => {
		vm.template.changeSeparateBy();
		vm.validateDateTime();
	};

	vm.onDateChange = () => {
		vm.template.resetHourOptions();
		vm.validateDateTime();
	};

	vm.onDateTimeChange = () => {
		vm.template.period.setStartAndEndAccordingToType();
		vm.validateDateTime();
	};

	vm.validateDateTime = () => {
		const errors = vm.template.validateDateTime();
		vm.errorDateBeforeMessage = errors.dateBefore;
		vm.error24HoursRangeMessage = errors.exceedsHoursRange;
	};

	vm.isStartDateAfterEndDate = () => {
		return vm.errorDateBeforeMessage;
	};

	vm.exceeds24HoursDuration = () => {
		return vm.error24HoursRangeMessage;
	};

	vm.hasInvalidDateOrTime = () => {
		if (vm.template.separateBy.isSeparateByHour()) {
			return vm.errorDateBeforeMessage || vm.error24HoursRangeMessage;
		}
		return vm.errorDateBeforeMessage;
	};

	vm.getFormattedHourOption = (hour) => {
		return SeparationByHourUtils.getFormattedHourOption(hour);
	};

	vm.save = () => {
		const errorEl = document.querySelector('.error');
		if (errorEl) {
			$timeout(() => {
				errorEl.focus();
			}, 0);
			return;
		}

		const showSuccessMessage = (isEditMode) => () => toaster.successMessage(`MESSAGE.REPORT_TEMPLATE_${isEditMode ? 'SAVE' : 'CREATE'}_SUCCESS`);
		const template = vm.template;

		if (!vm.isFormValid()) {
			return;
		}

		if (template.isInvalidLossRateReport()) {
			toaster.errorMessage('TEXT.REPORTS_TEMPLATE_DATE_RANGE_INFO_LOSS_RATE');
			return;
		}

		if (vm.filenameAutofill) {
			vm.template.setFilename(vm.template.getFilename() || vm.template.name);
		}

		InfoService.startSaveRequest();
		(vm.isEditMode ? ReportTemplates.update(template) : ReportTemplates.create(template))
			.then((templateId) => {
				template.id = templateId;

				InfoService.endRequest();
				$rootScope.$emit(Reports.EVENT.SAVED);

				switch (vm.saveActionType) {
					case vm.SAVE_ACTION_TYPE.OVERVIEW:
						vm.close();
						$state.go('reports.overview').then(showSuccessMessage(vm.isEditMode));
						break;
					case vm.SAVE_ACTION_TYPE.VIEW_REPORT:
						toaster.successMessage(`MESSAGE.REPORT_TEMPLATE_${vm.isEditMode ? 'SAVE' : 'CREATE'}_SUCCESS`, {
							onHideCallback: function onHideCallback() {
								triggerClose(templateId, 200);
							},
							timeout: 1000,
						});
						break;
					case vm.SAVE_ACTION_TYPE.GENERATE_EXPORT: {
						vm.close();
						$state.go('reports.overview').then(showSuccessMessage(vm.isEditMode)).then(generateExport(template));
						break;
					}
					default:
					// no action needed
				}
			})
			.catch(() => {
				toaster.errorMessage(`MESSAGE.REPORT_TEMPLATE_${vm.isEditMode ? 'SAVE' : 'CREATE'}_ERROR`);
				InfoService.endRequest();
			});
	};

	const generateExport = (template) => () => {
		InfoService.startRequest();
		template.setFilename(template.getFilename() || template.name);
		ReportExports.addTemplateToQueue(template)
			.then((reportId) => ReportExports.enableStatusCheckLoopForTemplateId(template.id, reportId))
			.catch((error) => {
				if (error.code === API_ERRORS.INVALID_REPORT_DATE) {
					if (template.isAnalyticsReport()) {
						toaster.errorMessage('TEXT.REPORTS_TEMPLATE_DATE_RANGE_INFO_ANALYTICS');
					} else if (template.isLossRateReport()) {
						toaster.errorMessage('TEXT.REPORTS_TEMPLATE_DATE_RANGE_INFO_LOSS_RATE');
					}
				} else {
					toaster.errorMessage('MESSAGE.GENERATE_EXPORT_ERROR');
				}
			})
			.finally(() => InfoService.endRequest());
	};

	vm.cancel = () => {
		vm.closeDialog();
		$state.go('reports.overview');
	};

	function triggerClose(result, delay) {
		ModalCloser.close(close, result, delay);
	}

	function setMinDate() {
		vm.minDate = null;
		if (vm.template.isAnalyticsReport()) {
			vm.minDate = startOfDay(subDays(new Date(), MAXIMUM_ANALYTICS_REPORT_RANGE_DAYS));
		}
		if (vm.template.isLossRateReport()) {
			vm.minDate = startOfDay(subDays(new Date(), MAXIMUM_LOSS_RATE_REPORT_RANGE_DAYS));
		}
	}
	vm.closeDialog = () => {
		ModalCloser.close(close, undefined, 200);
	};

	vm.delete = () => {
		$translate('MESSAGE.DELETE_PRESET_CONFIRMATION').then((confirmationText) => {
			// eslint-disable-next-line no-alert, no-restricted-globals
			if (confirm(`${confirmationText}\n${vm.initialName}`)) {
				InfoService.startDeleteRequest();
				ReportTemplates.delete(vm.template)
					.then(() => {
						InfoService.endRequest();
						$rootScope.$emit(Reports.EVENT.DELETED);
						vm.closeDialog();
						toaster.successMessage('MESSAGE.REPORT_TEMPLATE_DELETE_SUCCESS');
					})
					.catch(() => {
						toaster.successMessage('MESSAGE.REPORT_TEMPLATE_DELETE_ERROR');
						InfoService.endRequest();
					});
			}
		});
	};

	vm.isAlreadyPending = () => ReportExports.isPendingByTemplateId(vm.template.id);

	vm.canQueueAnotherExport = ReportExports.canQueueAnotherExport;

	vm.isReportValid = () => {
		return vm.reportValidity.dimensionsValid && vm.reportValidity.keyFiguresValid;
	};

	vm.isFormValid = () =>
		vm.template.name &&
		!vm.reportNameExists() &&
		vm.isValidExportName() &&
		vm.template.period.isValid() &&
		vm.isReportValid() &&
		!vm.template.keyFigureColumns.filter((column) => column.visible).find((column) => Reports.isKeyFigureRestricted(vm.template, column.id));

	vm.reportNameExists = () =>
		ReportTemplates.getList().filter((template) => template.name.toLowerCase() === (vm.template.name || '').toLowerCase() && template.id !== vm.template.id)
			.length > 0;

	vm.isValidExportName = () => vm.filenameAutofill || vm.template.exportSettings.isValidFilename();

	vm.close = function closeModal() {
		ModalCloser.close(close, undefined, 200);
	};
}

export default ReportsNewEditController;
